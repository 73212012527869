import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock,
} from "../../components/Heros"
import Spacer from "../../components/Spacer"
import MobileCarousel from "../../components/MobileCarousel"
import Carousel from "../../components/Carousel"
import {
  RightDualComponent
} from "../../components/Grid"
import Button from "../../components/Button"

import cx from "classnames"
import styles from "./styles.module.scss"

import whiteboardImg from "./images/whiteboard.png"
import cliffImg from "./images/cliff.png"
import highVisImg from "./images/vest.png"
import codingImg from "./images/coding.png"
import lindaImg from "./images/linda.png"
import { Link } from "gatsby";

const carouselData = [
  {
    img: whiteboardImg,
    title: "Innovation.",
    colour: "purple",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: whiteboardImg,
    title: "Anticipation.",
    colour: "blue",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: whiteboardImg,
    title: "Distruption.",
    colour: "pink",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: whiteboardImg,
    title: "Collaboration.",
    colour: "green",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  },
  {
    img: whiteboardImg,
    title: "Inspiration.",
    colour: "dark",
    copy: "Through clever, smart thinking, expertise and experiementation with evolving tech."
  }
]

const AboutUs = () => (
  <Layout JobSection>
    <Seo title="Sorted | About us" keywords={[`About us`, `Journey`, `History`, `Culture`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock
        title={["Creating powerful", "connections."]}
      >
        <p>Sorted is a global SaaS company, with API first technology frictionlessly running online checkouts, warehouses and shipping in 9 countries around the world using services from our carrier library of thousands.</p>
      </HeroCopyBlock>
    </PrimaryHero>

    <RightDualComponent
      rightComponent={<div className={styles.cliffBlock}><img src={cliffImg} alt="man on computer"/></div>}
      theme="purple"
      middleSplit
    >
      <div className={styles.easingecommerce}>
        <p>We’ve had nearly a decade of developing solutions to fix the broken carrier/retailer relationship.</p>
        <p>This has been possible with over £22m in investment to date, 99.999% up-time on our platform, over 50 carrier partnerships and 100 Sorted experts working from our Manchester Tech House (named in the top three most inspired spaces in the North of England).</p>
      </div>
    </RightDualComponent>

    <div className={styles.triContainerShapeHolder}>
      <div className={styles.triContainerShape} />
      <div className={cx("container-fluid", styles.triContainer)}>
        <div className={styles.triContainerGrid}>
          <div className={styles.triContaineJacket}>
            <img src={highVisImg} className={styles.triContainerImg} alt="viewing laptop in a high vis jacket"/>
          </div>
          <div className={styles.triContainerCopy}>
            <p className="text-white">Our agile and data-driven approach to carrier management, shipping and delivery tracking connects the retailer, the carrier and the all-important customer; maximising the potential of checkout delivery options, carrier integration, shipping management and post-purchase experience.</p>
          </div>
          <div className={styles.triContainerCoding}>
            <img src={codingImg} className={styles.triContainerImg} alt="someone coding"/>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-light">
      <div className="container-ultrathin">
        <Spacer size="medium" sides="topbottom">
          <h2 className="text-pink text-center">Our brand values.</h2>
          <p class="text-center">The values we hold, as an organisation, bleed into everything we do. They help us create the connections that delight retailers and carriers, allowing them to delight their customers.</p>
        </Spacer>
      </div>
    </div>

    <div className={styles.desktopCarousel}>
      <Carousel
        copyHeight="195px"
        items={carouselData}
      />
    </div>

    <div className={styles.mobileCarousel}>
      <MobileCarousel items={carouselData} />
    </div>

    <div className="bg-light">
      <div className="container-ultrathin">
        <Spacer size="large" sides="top">
          <h2 className="text-pink text-center">Your customers are changing.</h2>
        </Spacer>
        <Spacer size="medium" sides="topbottom">
          <p className="text-center">There’s a constant need for rapid and drastic reinvention in the retail industry – which is both very exciting, and quite overwhelming. It’s no wonder ecommerce retailers are struggling to innovate delivery at the same rate as the growth of consumer demand.</p>
          <p className="text-center">The challenge lies in restrictive technology and disjointed departments that need to be free to run impactful, efficient and future-proof programmes. Our research found that 91% of UK shoppers make up to 10 online orders each month. The opportunity is there for the trailblazers willing to act.</p>
        </Spacer>
      </div>
    </div>

    <RightDualComponent
      rightComponent={<div className={styles.cliffBlock}><img src={lindaImg} alt="man on computer"/></div>}
      theme="purple"
      middleSplit
    >
      <div className={styles.easingecommerce}>
        <h3>Delivering delight.</h3>
        <p>It’s time to close the gap between what customers expect and what retailers can provide in terms of delivery experience. Delivery should be a delight, for everyone.</p>
        <Button theme="white"><Link to="/">Learn more</Link></Button>
      </div>
    </RightDualComponent>

  </Layout>
)

export default AboutUs
